import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear();
        sessionStorage.clear();
        next();
      },
    },
    component: () => import("../views/account/logout"),
  },

  {
    path: "/reset-password/:token",
    name: "Reset Password",
    component: () => import("../views/account/reset-password.vue"),
    meta: {
      title: "Reset Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        // Continue to the login page
        next();
      },
    },
  },

  {
    path: "/",
    name: "default",
    meta: {
      title: "Dashboard",
      authRequired: true,
    },
    component: () => import("../views/pages/starter.vue"),
  },

  // Menus
  {
    path: "/settings/menus",
    name: "Menus",
    meta: { title: "Menu", authRequired: true },
    component: () => import("../views/maintenance/menus/index"),
  },
  {
    path: "/settings/menus/form",
    name: "AddMenu",
    meta: { title: "Add Menu", authRequired: true },
    component: () => import("../views/maintenance/menus/form"),
  },
  {
    path: "/settings/menus/form/:id",
    name: "EditMenu",
    meta: { title: "Edit Menu", authRequired: true },
    component: () => import("../views/maintenance/menus/form"),
  },

  // Permissions
  {
    path: "/settings/permissions",
    name: "Permissions",
    meta: { title: "Permission", authRequired: true },
    component: () => import("../views/user-management/permissions/index"),
  },
  {
    path: "/settings/permissions/form",
    name: "AddPermission",
    meta: { title: "Add Permission", authRequired: true },
    component: () => import("../views/user-management/permissions/form"),
  },
  {
    path: "/settings/permissions/form/:id",
    name: "EditPermission",
    meta: { title: "Edit Permission", authRequired: true },
    component: () => import("../views/user-management/permissions/form"),
  },

  // Roles
  {
    path: "/settings/roles",
    name: "Roles",
    meta: { title: "Role", authRequired: true },
    component: () => import("../views/user-management/roles/index"),
  },
  {
    path: "/settings/roles/:id",
    name: "ViewRole",
    meta: { title: "View Role", authRequired: true },
    component: () => import("../views/user-management/roles/info"),
  },
  {
    path: "/settings/roles/form",
    name: "AddRole",
    meta: { title: "Add Role", authRequired: true },
    component: () => import("../views/user-management/roles/form"),
  },
  {
    path: "/settings/roles/form/:id",
    name: "EditRole",
    meta: { title: "Edit Role", authRequired: true },
    component: () => import("../views/user-management/roles/form"),
  },

  // Modules
  {
    path: "/settings/modules",
    name: "Modules",
    meta: { title: "Module", authRequired: true },
    component: () => import("../views/user-management/modules/index"),
  },
  {
    path: "/settings/modules/form",
    name: "AddModule",
    meta: { title: "Add Module", authRequired: true },
    component: () => import("../views/user-management/modules/form"),
  },
  {
    path: "/settings/modules/form/:id",
    name: "EditModule",
    meta: { title: "Edit Module", authRequired: true },
    component: () => import("../views/user-management/modules/form"),
  },

  // Users
  {
    path: "/settings/users",
    name: "Users",
    meta: { title: "User", authRequired: true },
    component: () => import("../views/user-management/users/index"),
  },
  {
    path: "/settings/users/:id",
    name: "UserProfile",
    meta: { title: "User Profile", authRequired: true },
    component: () => import("../views/user-management/users/profile"),
  },
  {
    path: "/users/profile/:id",
    name: "UserProfile2",
    meta: { title: "User Profile", authRequired: true },
    component: () => import("../views/user-management/users/user-profile"),
  },
  {
    path: "/settings/users/form",
    name: "AddUser",
    meta: { title: "Add User", authRequired: true },
    component: () => import("../views/user-management/users/form"),
  },
  {
    path: "/settings/users/form/:id",
    name: "EditUser",
    meta: { title: "Edit User", authRequired: true },
    component: () => import("../views/user-management/users/form"),
  },

  {
    path: "/user/profile",
    name: "UserProfileView",
    meta: { title: "User", authRequired: true },
    component: () => import("../views/user-management/users/user-profile"),
  },

  // Operating Unit
  {
    path: "/maintenance/ou",
    name: "OperatingUnit",
    meta: { title: "Operating Unit", authRequired: true },
    component: () => import("../views/maintenance/ou"),
  },

  // Files

  // Projects
  {
    path: "/files/project",
    name: "Project",
    meta: { title: "Project", authRequired: true },
    component: () => import("../views/files/project"),
  },

  // Payment Scheme
  {
    path: "/files/unit",
    name: "Unit",
    meta: { title: "Unit", authRequired: true },
    component: () => import("../views/files/unit"),
  },

  // Payment Scheme
  {
    path: "/files/payment-scheme",
    name: "PaymentScheme",
    meta: { title: "Payment Scheme", authRequired: true },
    component: () => import("../views/files/payment-scheme"),
  },

  // Realty Firm
  {
    path: "/files/realty-firm",
    name: "RealtyFirm",
    meta: { title: "Realty Firm", authRequired: true },
    component: () => import("../views/files/realty-firm"),
  },

  // Agent
  {
    path: "/files/agent",
    name: "Agent",
    meta: { title: "Agent", authRequired: true },
    component: () => import("../views/files/agent"),
  },

  // Collector
  {
    path: "/files/collector",
    name: "Collector",
    meta: { title: "Collector", authRequired: true },
    component: () => import("../views/files/collector"),
  },

  // Customer
  {
    path: "/files/customer",
    name: "Customer",
    meta: { title: "Customer", authRequired: true },
    component: () => import("../views/files/customer"),
  },

  // Maintenance

  // Location
  {
    path: "/maintenance/location",
    name: "Location",
    meta: { title: "Location", authRequired: true },
    component: () => import("../views/maintenance/location"),
  },

  // Price Adjustment Handler
  {
    path: "/maintenance/price-adjustment-handler",
    name: "PriceAdjustmentHandler",
    meta: { title: "Price Adjustment Handler", authRequired: true },
    component: () => import("../views/maintenance/price-adjustment-handler"),
  },

  // Unit Type
  {
    path: "/maintenance/unit-type",
    name: "UnitType",
    meta: { title: "Unit Type", authRequired: true },
    component: () => import("../views/maintenance/unit-type"),
  },

  // Designation
  {
    path: "/maintenance/designation",
    name: "Designation",
    meta: { title: "Designation", authRequired: true },
    component: () => import("../views/maintenance/designation"),
  },

  // File Employee Forms
  {
    path: "/files/employee/downloadable-form",
    name: "EmployeeDownloadableForms",
    meta: { title: "Employee Downloadable Forms", authRequired: true },
    component: () => import("../views/files/employee/downloadable-form"),
  },

  // File Employee Memos
  {
    path: "/files/employee/memos",
    name: "EmployeeMemos",
    meta: { title: "Employee Memos", authRequired: true },
    component: () => import("../views/files/employee/memos"),
  },

  // File Employee Payslip
  {
    path: "/files/employee/payslip",
    name: "EmployeePayslip",
    meta: { title: "Employee Payslip", authRequired: true },
    component: () => import("../views/files/employee/payslip"),
  },

  // Bank
  {
    path: "/maintenance/bank",
    name: "Bank",
    meta: { title: "Bank", authRequired: true },
    component: () => import("../views/maintenance/bank"),
  },

  // Reservation Routes
  {
    path: "/reservation/record",
    name: "ReservationRecord",
    meta: { title: "Reservation Record", authRequired: true },
    component: () => import("../views/reservation"),
  },

  {
    path: "/reservation/commission",
    name: "ReservationCommission",
    meta: { title: "Reservation Commission", authRequired: true },
    component: () => import("../views/reservation/commission"),
  },

  {
    path: "/reservation/commission/report",
    name: "ReservationCommissionReport",
    meta: { title: "Reservation Commission Report", authRequired: true },
    component: () => import("../views/reservation/report"),
  },

  // Sales Routes
  {
    path: "/sales/record",
    name: "SalesRecord",
    meta: { title: "Sales Record", authRequired: true },
    component: () => import("../views/sales"),
  },

  {
    path: "/sales/report",
    name: "Salesreport",
    meta: { title: "Sales Report", authRequired: true },
    component: () => import("../views/sales/report"),
  },

  // Financing Routes
  {
    path: "/financing/record",
    name: "FinancingRecord",
    meta: { title: "Financing Record", authRequired: true },
    component: () => import("../views/financing"),
  },

  {
    path: "/financing/report",
    name: "Financingreport",
    meta: { title: "Financing Report", authRequired: true },
    component: () => import("../views/financing/report"),
  },

  // Collection Routes
  {
    path: "/collection/final-soa",
    name: "FinalSOA",
    meta: { title: "Final SOA", authRequired: true },
    component: () => import("../views/collection/final-soa"),
  },

  {
    path: "/collection/customer-ledger",
    name: "CustomerLedger",
    meta: { title: "Customer Ledger", authRequired: true },
    component: () => import("../views/collection/customer-ledger"),
  },

  {
    path: "/collection/record",
    name: "CollectionRecord",
    meta: { title: "Collection Record", authRequired: true },
    component: () => import("../views/collection/record"),
  },

  {
    path: "/collection/report",
    name: "CollectionReport",
    meta: { title: "Collection Report", authRequired: true },
    component: () => import("../views/collection/report"),
  },

  // Receivable Routes
  {
    path: "/receivable/record",
    name: "PaymentRecord",
    meta: { title: "Payment Record", authRequired: true },
    component: () => import("../views/receivable/"),
  },

  {
    path: "/receivable/receipt",
    name: "PaymentReceipt",
    meta: { title: "Payment Receipt", authRequired: true },
    component: () => import("../views/receivable/receipt"),
  },

  {
    path: "/receivable/report",
    name: "PaymentReport",
    meta: { title: "Payment Report", authRequired: true },
    component: () => import("../views/receivable/report"),
  },

  // Commission Routes
  {
    path: "/commission/ledger",
    name: "CommissionLedger",
    meta: { title: "Commission Ledger", authRequired: true },
    component: () => import("../views/commission/ledger"),
  },

  {
    path: "/commission/receipt",
    name: "CommissionReceipt",
    meta: { title: "Commission Receipt", authRequired: true },
    component: () => import("../views/commission/receipt"),
  },

  {
    path: "/commission/report",
    name: "CommissionReport",
    meta: { title: "Commission Report", authRequired: true },
    component: () => import("../views/commission/report"),
  },

  // Position
  {
    path: "/maintenance/position",
    name: "Position",
    meta: { title: "Position", authRequired: true },
    component: () => import("../views/maintenance/position"),
  },

  // Department
  {
    path: "/maintenance/sales-team",
    name: "SalesTeam",
    meta: { title: "Department", authRequired: true },
    component: () => import("../views/maintenance/sales-team"),
  },

  // Expense Particular
  {
    path: "/maintenance/expense-particular",
    name: "ExpenseParticular",
    meta: { title: "Expense Particular", authRequired: true },
    component: () => import("../views/maintenance/expense-particular"),
  },

  // Expense Category
  {
    path: "/maintenance/expense-category",
    name: "ExpenseCategory",
    meta: { title: "Expense Category", authRequired: true },
    component: () => import("../views/maintenance/expense-category"),
  },

  // Expense Route
  {
    path: "/expense/record",
    name: "Expense",
    meta: { title: "Expense", authRequired: true },
    component: () => import("../views/expense"),
  },

  {
    path: "/expense/report",
    name: "ExpenseReport",
    meta: { title: "Expense Report", authRequired: true },
    component: () => import("../views/expense/report"),
  },

  // Payee
  {
    path: "/maintenance/payee",
    name: "Payee",
    meta: { title: "Payee", authRequired: true },
    component: () => import("../views/maintenance/payee"),
  },

  // Incentive
  {
    path: "/incentive/ledger",
    name: "IncentiveLedger",
    meta: { title: "Incentive Ledger", authRequired: true },
    component: () => import("../views/incentive/ledger"),
  },

  {
    path: "/incentive/record",
    name: "IncentiveRecord",
    meta: { title: "Incentive Record", authRequired: true },
    component: () => import("../views/incentive/record"),
  },

  {
    path: "/incentive/report",
    name: "IncentiveReport",
    meta: { title: "Incentive Report", authRequired: true },
    component: () => import("../views/incentive/report"),
  },

  // Sales Return
  {
    path: "/sales-return/record",
    name: "SalesReturn",
    meta: { title: "Sales Return", authRequired: true },
    component: () => import("../views/sales-return/"),
  },

  {
    path: "/sales-return/report",
    name: "SalesReturnReport",
    meta: { title: "Sales Return Report", authRequired: true },
    component: () => import("../views/sales-return/report"),
  },

  // Assumed Units
  {
    path: "/assumed-unit/record",
    name: "AssumeUnit",
    meta: { title: "Assumed Units", authRequired: true },
    component: () => import("../views/assumed-unit/"),
  },

  {
    path: "/assumed-unit/report",
    name: "AssumeUnitReport",
    meta: { title: "Assumed Units Report", authRequired: true },
    component: () => import("../views/assumed-unit/report"),
  },

  // Seller Management
  {
    path: "/seller-management/salesperson",
    name: "Salesperson",
    meta: { title: "Salesperson", authRequired: true },
    component: () => import("../views/seller-management/salesperson"),
  },

  {
    path: "/seller-management/team-leader",
    name: "TeamLeader",
    meta: { title: "Team Leader", authRequired: true },
    component: () => import("../views/seller-management/team-leader"),
  },

  {
    path: "/seller-management/unit-manager",
    name: "UnitManager",
    meta: { title: "Unit Manager", authRequired: true },
    component: () => import("../views/seller-management/unit-manager"),
  },

  {
    path: "/seller-management/sales-manager",
    name: "SalesManager",
    meta: { title: "Sales Manager", authRequired: true },
    component: () => import("../views/seller-management/sales-manager"),
  },

  {
    path: "/seller-management/sales-director",
    name: "SalesDirector",
    meta: { title: "Sales Director", authRequired: true },
    component: () => import("../views/seller-management/sales-director"),
  },

  {
    path: "/seller-management/broker",
    name: "Broker",
    meta: { title: "Broker", authRequired: true },
    component: () => import("../views/seller-management/broker"),
  }
];